import React, { useState } from 'react';
import { Configure, connectSearchBox, InstantSearch } from 'react-instantsearch-dom';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';

import Container from 'layout/Container';
import SearchBar from 'common/SearchBar';

import { Results } from '../SearchResults/Results';
import { ConnectedFaqCategories } from './FaqCategories';
import { ConnectedFaqItem } from './FaqItem';

import './FaqComponent.scss';

const algoliaFaqIndex = `${process.env.GATSBY_ALGOLIA_INDEX}-faq`;

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_PUBLIC_KEY as string
);

const InstantSearchBar = connectSearchBox(SearchBar);

const FaqComponent = ({
  faqItems,
  faqSearchBar: { searchBarText, placeholder, customButton },
  noResultText,
  noResultTitle,
  allProductDetails,
  isHeroBanner,
}: PageContent.FaqComponentType) => {
  const anchorlinks = faqItems.map(({ faqItemTitle }) => ({
    count: 1,
    isRefined: false,
    label: faqItemTitle,
    value: faqItemTitle,
  }));

  const [isTyping, setIsTyping] = useState('');

  return (
    <section className="faq-component" id="main-content" data-testid="faqComponent">
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaFaqIndex}
        searchState={{ query: isTyping }}
      >
        <InstantSearchBar
          {...{
            searchBarText,
            placeholder,
            customButton,
            onChange: (event) => setIsTyping(event),
            isLiveSearch: true,
          }}
        />

        <Configure highlightPreTag={'<mark class="highlighted-text">'} highlightPostTag="</mark>" />
        <div className="faq-component__categories">
          <div className="faq-component__categories-container" />
        </div>
        <ConnectedFaqCategories
          attribute="category"
          anchorLinks={anchorlinks}
          transformItems={(items) =>
            anchorlinks.map((item) => {
              if (!items.find((anchor) => anchor.value === item.value)) {
                return {
                  ...item,
                  count: 1,
                  shadowed: true,
                };
              }

              return item;
            })
          }
        />
        <Container>
          <Results text={noResultText} {...{ noResultTitle, isHeroBanner }}>
            <ConnectedFaqItem {...{ isTyping, allProductDetails }} />
          </Results>
        </Container>
      </InstantSearch>
    </section>
  );
};

export const FaqComponentType = graphql`
  fragment FaqComponentType on FaqComponentType {
    faqSearchBar {
      ...SearchBarType
    }
    faqItems {
      faqItemTitle
      accordionItems {
        ...AccordionItemType
      }
    }
    noResultTitle
    noResultText
  }
`;

export default FaqComponent;
