import camelCase from 'lodash/camelCase';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import { StringConventionType } from './models';

export const toPascalCase: StringConventionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const toAnchorId: StringConventionType = (str) => lowerCase(str).replace(/ /g, '-');

const stringUtils = {
  toPascalCase,
  toAnchorId,
};

export default stringUtils;
