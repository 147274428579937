import React from 'react';
import { connectMenu } from 'react-instantsearch-dom';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { toAnchorId } from 'utils/stringUtils/stringUtils';

import './FaqCategories.scss';

const FaqCategories = ({ items, anchorLinks }: PageContent.FaqCategoriesType) => (
  <div className="faq-categories" data-testid="faq-categories">
    <div className="faq-categories__container">
      {items.length
        ? items.map((item) =>
            item.shadowed ? (
              <h4 key={item.label} className="faq-categories__shadowed">
                {item.label}
              </h4>
            ) : (
              <AnchorLink key={item.label} to={`#${toAnchorId(item.label)}`}>
                <h4>{item.label}</h4>
              </AnchorLink>
            )
          )
        : anchorLinks.map((anchorLink) => (
            <h4 key={anchorLink.label} className="faq-categories__shadowed">
              {anchorLink.label}
            </h4>
          ))}
    </div>
  </div>
);

const ConnectedFaqCategories = connectMenu(FaqCategories);

export { ConnectedFaqCategories, FaqCategories };
