import React from 'react';
import { connectHits } from 'react-instantsearch-dom';

import AccordionItem from 'components/AccordionItems/AccordionItem';
import { toAnchorId } from 'utils/stringUtils/stringUtils';

import './FaqItem.scss';

const FaqItem = ({ isTyping, hits, allProductDetails }: PageContent.FaqItemType) => {
  const convertToAccordionItem = (items, productItems) =>
    items.map((item) => {
      if (
        item.title.matchLevel !== 'none' ||
        item.description.matchLevel !== 'none' ||
        item.products ||
        !isTyping
      ) {
        const filteredProducts = productItems.filter(
          (productItem) => productItem.title === item.title.value
        );

        return {
          title: item.title.value,
          description: item.description.value,
          products: filteredProducts.length ? filteredProducts[0].products : [],
        };
      }

      return null;
    });

  return hits?.map(({ category, _highlightResult, content }) => (
    <div className="faq-item" data-testid="faq-item" id={toAnchorId(category)} key={category}>
      <h3 className="faq-item__title">{category}</h3>
      <div className="accordion-items" data-testid="accordion-items">
        {convertToAccordionItem(_highlightResult.content, content).map((accordionItem) =>
          accordionItem ? (
            <AccordionItem
              key={accordionItem.title}
              {...{
                ...accordionItem,
                allProductDetails,
                initialOpen: !!isTyping,
              }}
            />
          ) : null
        )}
      </div>
    </div>
  ));
};

const ConnectedFaqItem = connectHits(FaqItem);

export { ConnectedFaqItem, FaqItem };
