import React from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import FaqComponent from 'components/FaqComponent';

const Faq = ({
  data: {
    faq: { urls, seo, heroBanner, videoBanner, faqContent },
    allProductDetails,
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: FaqTypes.FaqProps) => {
  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  const isHeroBanner = Boolean(heroBanner);

  return (
    <Layout {...{ crumbsSettings }} headerTransparent>
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...heroBanner} />
      <FaqComponent {...faqContent} {...{ allProductDetails, isHeroBanner }} />
      <Container>
        <Banner {...videoBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String) {
    faq(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      heroBanner {
        ...BannerType
      }
      faqContent {
        ...FaqComponentType
      }
      videoBanner {
        ...BannerType
      }
    }
    allProductDetails {
      nodes {
        title
        productImage {
          ...SalsifyImageType
        }
        sku
        url
      }
    }
  }
`;

export default Faq;
